import React, { useState, useRef, useCallback } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import FileImageIconSVG from "../icons/FileImageIconSVG";
import CopyIconBlackSVG from "../icons/CopyIconBlackSVG";
import { toPng } from "html-to-image";
import ExcelIconSVG from "../icons/ExcelIconSVG";
import ArrowRightIconSVG from "../icons/ArrowRightIconSVG";
import * as XLSX from "xlsx"; // Импортируем библиотеку XLSX
import QuestionMode from "./QuestionMode";
import UserMode from "./UserMode";
import { useSurveyStore } from "../stores/useSurveyStore";

const SurveyRepliesComponent = ({ surveyJson }) => {
    const { getUserById } = useSurveyStore();
    const dataFromSurvey = surveyJson?.user_answers;
    console.log("dataFromSurvey", dataFromSurvey);

    const questions = surveyJson?.questions;
    // console.log("questions", questions);

    const data = [];

    function getAnswersByType(item) {
        if (item.type === "multiple-choice") {
            // Если multiple-choice, парсим строку в массив
            return JSON.parse(item.answer);
        } else if (item.type === "yes-no") {
            return item.answer === "true" ? ["Да"] : ["Нет"];
        } else {
            return [item.answer];
        }
    }

    let totalUsers = new Set();

    dataFromSurvey?.map((item) => {
        const questionInSurveyJson = questions.find(
            (i) => i.id === item?.question_id
        );
        // console.log("questionInSurveyJson", questionInSurveyJson);
        if (!questionInSurveyJson?.type) {
            item.is_possibly_deleted = true;
        }
        item.type = questionInSurveyJson?.type || "short-text";

        // Увеличиваем счётчик общего количества пользователей
        totalUsers.add(item.user_id);

        // Используем функцию для получения массива ответов
        let answersArray = getAnswersByType(item);

        const question = data.find((i) => i.question_id === item.question_id);
        if (!question) {
            // Если вопрос еще не добавлен, добавляем его
            data.push({
                question_id: item.question_id,
                question_title: item.question_title,
                question_type: item.type,
                total_answers: 1, // Указываем общее количество ответов на вопрос
                answers: answersArray.map((answer) => ({
                    name: answer,
                    value: 1,
                })),
                is_possibly_deleted: item.is_possibly_deleted,
            });
        } else {
            // Если вопрос уже существует, обновляем ответы
            question.total_answers += 1; // Увеличиваем общее количество ответов
            answersArray.forEach((answer) => {
                const existingAnswer = question.answers.find(
                    (i) => i.name === answer
                );
                if (existingAnswer) {
                    existingAnswer.value += 1;
                } else {
                    question.answers.push({
                        name: answer,
                        value: 1,
                    });
                }
            });
        }
    });

    // Теперь добавим общее количество пользователей, прошедших опрос, в результирующий массив data
    data.totalUsers = totalUsers.size;
    // console.log("data", data);

    const [mode, setMode] = useState("review");
    const refs = useRef([]);

    const setRef = useCallback((node, index) => {
        if (node) {
            refs.current[index] = node; // Сохраняем каждый блок в массив рефов
        }
    }, []);

    const handleCopyImage = (index) => {
        const blockNode = refs.current[index];
        if (blockNode) {
            toPng(blockNode)
                .then((dataUrl) => {
                    const img = new Image();
                    img.src = dataUrl;

                    navigator.clipboard
                        .write([
                            new ClipboardItem({
                                "image/png": fetch(dataUrl)
                                    .then((res) => res.blob())
                                    .then((blob) => blob),
                            }),
                        ])
                        .then(() => {
                            alert("Блок скопирован как изображение!");
                        })
                        .catch((err) => {
                            console.error("Ошибка при копировании:", err);
                        });
                })
                .catch((err) => {
                    console.error("Ошибка при создании изображения:", err);
                });
        }
    };
    const BASE_COLORS = ["#FF0000", "#8A2BE2", "#00CED1"];

    function getMixedColor(index) {
        // Смешиваем базовые цвета и добавляем случайности
        const hue = Math.floor(Math.random() * 360);
        const saturation = 70 + Math.random() * 30;
        const lightness = 50 + Math.random() * 20;
        return index % 2 === 0
            ? `hsl(${hue}, ${saturation}%, ${lightness}%)`
            : BASE_COLORS[index % BASE_COLORS.length];
    }

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const handleExportToExcel = () => {
        // Группируем данные по пользователям
        const usersMap = new Map();
        const questionOptions = new Map(); // Карта с вариантами ответов для multiple-choice

        dataFromSurvey.forEach((el) => {
            const user = getUserById(el.user_id);
            const userKey = `${user.name} - ${user.deportament || "Не указан"}`;

            if (!usersMap.has(userKey)) {
                usersMap.set(userKey, {
                    user_name: user.name,
                    department: user.deportament || "Не указан",
                    answers: {},
                });
            }

            if (el.type === "multiple-choice") {
                // Парсим ответы, если это multiple-choice
                let selectedOptions = [];
                try {
                    selectedOptions = JSON.parse(el.answer); // Преобразуем в массив
                } catch (error) {
                    console.error(
                        "Ошибка парсинга multiple-choice:",
                        el.answer
                    );
                }

                if (!questionOptions.has(el.question_title)) {
                    questionOptions.set(el.question_title, new Set());
                }
                selectedOptions.forEach((option) =>
                    questionOptions.get(el.question_title).add(option)
                );

                // Записываем выбор пользователя
                selectedOptions.forEach((option) => {
                    usersMap.get(userKey).answers[
                        `${el.question_title} / ${option}`
                    ] = "true";
                });
            } else {
                // Обычный вопрос — просто записываем ответ
                usersMap.get(userKey).answers[el.question_title] = el.answer;
            }
        });

        // Создаём заголовки: ФИО, отдел, вопросы и варианты multiple-choice
        let questions = [
            ...new Set(dataFromSurvey.map((el) => el.question_title)),
        ];

        // Разворачиваем multiple-choice вопросы в отдельные колонки
        let expandedQuestions = [];
        questions.forEach((q) => {
            if (questionOptions.has(q)) {
                expandedQuestions.push(
                    ...Array.from(questionOptions.get(q)).map(
                        (option) => `${q} / ${option}`
                    )
                );
            } else {
                expandedQuestions.push(q);
            }
        });

        const headers = ["ФИО", "Отдел", ...expandedQuestions];

        // Формируем строки данных
        const rows = Array.from(usersMap.values()).map((user) => {
            return [
                user.user_name,
                user.department,
                ...expandedQuestions.map((q) => user.answers[q] || ""),
            ];
        });

        // Создаём лист для Excel
        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);

        // Устанавливаем ширину столбцов
        ws["!cols"] = [
            { wch: 30 }, // ФИО
            { wch: 30 }, // Отдел
            ...expandedQuestions.map(() => ({ wch: 20 })), // Фиксированная ширина для вопросов
        ];

        // Применяем перенос текста внутри ячеек
        Object.keys(ws).forEach((cell) => {
            if (ws[cell] && ws[cell].t) {
                ws[cell].s = {
                    alignment: { wrapText: true, vertical: "center" },
                };
            }
        });

        // Создаём книгу и добавляем лист
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Survey Results");

        // Экспортируем в файл
        XLSX.writeFile(wb, "survey_results.xlsx");
    };

    return (
        <div className="flex flex-col justify-start">
            <div className="bg-gray-50 flex flex-col gap-8">
                <div className="justify-between items-center inline-flex">
                    <div className="text-center text-[#111928] text-2xl font-medium leading-9">
                        {data?.totalUsers} ответов
                    </div>
                    <div
                        role="button"
                        onClick={handleExportToExcel}
                        className="px-3 py-2 rounded-lg justify-center items-center gap-2 flex"
                    >
                        <ExcelIconSVG />
                        <div className="text-[#1f2a37] text-xs font-medium leading-[18px]">
                            Экспортировать в таблицу
                        </div>
                        <ArrowRightIconSVG />
                    </div>
                </div>
                <div className="justify-start items-center gap-8 inline-flex">
                    <div
                        role="button"
                        onClick={() => setMode("review")}
                        className={
                            "px-4 py-3 rounded-md flex-col justify-center items-center inline-flex " +
                            (mode === "review" ? "bg-gray-200" : "")
                        }
                    >
                        <div
                            className={
                                "text-sm font-medium leading-tight " +
                                (mode === "review"
                                    ? "text-[#111928]"
                                    : "text-gray-500")
                            }
                        >
                            Сводка
                        </div>
                    </div>
                    <div
                        role="button"
                        onClick={() => setMode("question")}
                        className={
                            "px-4 py-3 rounded-md flex-col justify-center items-center inline-flex " +
                            (mode === "question" ? "bg-gray-200" : "")
                        }
                    >
                        <div
                            className={
                                "text-sm font-medium leading-tight " +
                                (mode === "question"
                                    ? "text-[#111928]"
                                    : "text-gray-500")
                            }
                        >
                            Вопрос
                        </div>
                    </div>
                    <div
                        role="button"
                        onClick={() => setMode("user")}
                        className={
                            "px-4 py-3 rounded-md flex-col justify-center items-center inline-flex " +
                            (mode === "user" ? "bg-gray-200" : "")
                        }
                    >
                        <div
                            className={
                                "text-sm font-medium leading-tight " +
                                (mode === "user"
                                    ? "text-[#111928]"
                                    : "text-gray-500")
                            }
                        >
                            Отдельный пользователь
                        </div>
                    </div>
                </div>
                {mode === "review" && (
                    <>
                        {data?.map((el, index) => (
                            <div
                                ref={(node) => setRef(node, index)} // Присваиваем реф каждому блоку
                                key={el.question_id}
                                className="p-5 bg-white rounded-xl shadow border border-gray-100 flex-col justify-center items-start gap-5 inline-flex"
                            >
                                <div className="self-stretch justify-between items-center inline-flex">
                                    <div className="flex-col justify-start items-start gap-2 inline-flex">
                                        <div className="text-[#111928] text-base font-medium leading-normal">
                                            {el.question_title}
                                        </div>
                                        <div className="text-gray-500 text-sm font-medium leading-tight">
                                            {el.total_answers} ответов
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            role="button"
                                            onClick={() =>
                                                handleCopyImage(index)
                                            } // Копируем конкретный блок
                                            className="h-[34px] px-3 py-2 rounded-lg border border-gray-200 justify-center items-center gap-2 flex"
                                        >
                                            <CopyIconBlackSVG />
                                            <div className="text-[#1f2a37] text-xs font-medium leading-[18px]">
                                                Копировать
                                            </div>
                                        </div>
                                        {el.is_possibly_deleted && (
                                            <div className="text-red-500 text-xs font-medium leading-[18px]">
                                                Вопрос, возможно, был удален
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {el.question_type !== "short-text" &&
                                    el.question_type !== "long-text" &&
                                    el.question_type !== "file" && (
                                        <PieChart width={800} height={400}>
                                            <Pie
                                                data={el.answers}
                                                cx={200}
                                                cy={200}
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                outerRadius={150}
                                                fill="#8884d8"
                                                dataKey="value"
                                            >
                                                {el.answers?.map(
                                                    (entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={getMixedColor(
                                                                index
                                                            )}
                                                        />
                                                    )
                                                )}
                                            </Pie>
                                            <Tooltip />
                                            <Legend
                                                layout="vertical"
                                                align="right"
                                                verticalAlign="middle"
                                            />
                                        </PieChart>
                                    )}
                                {(el.question_type === "short-text" ||
                                    el.question_type === "long-text") && (
                                    <>
                                        {el.answers?.map((answer, index) => (
                                            <div
                                                key={index}
                                                className="p-3 bg-gray-50 rounded-lg justify-start items-center gap-3 inline-flex"
                                            >
                                                <div className="grow shrink basis-0 text-[#111928] text-sm font-normal leading-tight">
                                                    {answer.name
                                                        .split("\n")
                                                        .map((line, index) => (
                                                            <div key={index}>
                                                                {line}
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                                {el.question_type === "file" && (
                                    <>
                                        {el.answers?.map((answer, index) => (
                                            <div
                                                key={index}
                                                className="h-11 p-3 bg-gray-50 rounded-lg justify-start items-center gap-3 inline-flex"
                                            >
                                                <FileImageIconSVG />
                                                <div className="grow shrink basis-0 text-[#111928] text-sm font-normal font-['Inter'] leading-tight">
                                                    {answer.name}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        ))}
                    </>
                )}
                {mode === "question" && (
                    <QuestionMode questions={questions} answers={data} />
                )}
                {mode === "user" && <UserMode surveyJson={surveyJson} />}
            </div>
        </div>
    );
};

export default SurveyRepliesComponent;
